import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import JoinUs from '../../sections/joinUs'
import Products from '../../sections/gallery-products'

export default ({ data }) => {
  const d = data.postgres.allProductsItems.edges.filter(i => i.node.price.toLowerCase() !== 'sold out')
  const filteredData = {postgres: { allProductsItems: { edges: d}}}

  return (
  <Layout>
    <SEO title={`Underwoods Specials`} />
    <Products data={filteredData} title={`Underwood's Monthly Special Collection`}/>
    <JoinUs />
  </Layout>
)}

export const SpecialsQuery = graphql`
  query specialsQuery {
    postgres {
      allProductsItems(first: 100, orderBy: CREATED_AT_DESC, condition: {collection: "Specials"}) {
        edges{
        node {
          price
          title
          id
          image
          designer
          description
          sku
          purchasable
        }
      }
    }
  }
}
`
